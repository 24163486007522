import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Expertise } from 'views/index/expertises-section/components/expertise';
import { SPECIALIZATIONS_DATA } from 'views/web-development/constants';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';

const SContainer = styled(Container)`
    background-color: var(--gray-color-90);
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
`;

const SSWrapper = styled.div`
    padding: 7.5rem 0;
    ${CONSTANTS.MEDIA.max1024`
        padding: 6.5rem 0;
    `}
`;

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    margin-bottom: 4.375rem;

    ${CONSTANTS.MEDIA.max1024`
        margin-bottom: 2.25rem;
    `}
`;

const SWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4.375rem 2.5rem;

    ${CONSTANTS.MEDIA.max768`
        grid-template-columns: 1fr;
    `}
`;

const SSpecialization = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`;

export const SpecializationsWallSection = () => {
    return (
        <SContainer id="specializations">
            <SSWrapper>
                <SHeaderSecond>
                    <FormattedMessage id="specializations.title" />
                </SHeaderSecond>
                <SWrapper>
                    {SPECIALIZATIONS_DATA.map((specialization) => (
                        <SSpecialization>
                            <Expertise
                                key={specialization.title}
                                expertise={specialization}
                                to={specialization.to}
                            />
                        </SSpecialization>
                    ))}
                </SWrapper>
            </SSWrapper>
        </SContainer>
    );
};
