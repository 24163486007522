import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import { RowWithTextImg } from 'modules/row-text-img';

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

export const CareOfQualitySection = () => {
    const { formatMessage } = useIntl();
    const images = useStaticQuery(graphql`
        {
            background: file(
                relativePath: { eq: "web-development/care-of-quality.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);
    return (
        <Container>
            <RowWithTextImg
                title="view-web-projects-quality-section.title"
                descriptions={[
                    formatMessage(
                        {
                            id: 'view-web-projects-quality-section.desc.0',
                        },
                        {
                            link: (chunks) => (
                                <SPrefixLangLink to={PATHS.TEAM}>
                                    {chunks}
                                </SPrefixLangLink>
                            ),
                        },
                    ),
                ]}
                img={images.background}
                alt={formatMessage({
                    id: 'view-web-projects-quality-section.alt',
                })}
                isDescriptionFormattedPreviously
            />
        </Container>
    );
};
