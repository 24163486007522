import React from 'react';
import { css } from 'styled-components';
import { BadgesSection } from 'views/web-development/badges-section';
import { CareOfQualitySection } from 'views/web-development/care-of-quality-section';
import {
    LATEST_RELATED_ARTICLES_SETTINGS,
    useCaseStudiesData,
} from 'views/web-development/constants';
import { PartnershipSection } from 'views/web-development/partnership-section';
import { SpecializationsWallSection } from 'views/web-development/specializations-section';
import { WhyWebDevSection } from 'views/web-development/why-web-dev-section';
import { AnimatedWebDevelopment } from 'components/animated-web-development';
import { YoutubePlayer } from 'components/youtube-player';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { FooterSection } from 'modules/footer-section';
import { MainSection } from 'modules/main-section';
import { PerksWithImageSection } from 'modules/perks-with-image-section';
import { QuoteBannerSection } from 'modules/quote-banner-section';
import { ServicesCaseStudiesSection } from 'modules/services-case-studies-section';
import { array } from 'prop-types';

import { PERKS } from './constants';

const Props = {
    faqData: array.isRequired,
};

export const WebDevelopmentView = ({ faqData }) => {
    return (
        <>
            <MainSection
                icon={<AnimatedWebDevelopment />}
                iconWrapperCss={css`
                    max-width: 34.25rem;
                `}
                titleId="view-web-projects-main-section.title"
                subtitles={['view-web-projects-main-section.subtitle']}
                estimateProjectAnalytics={
                    gaTrackedEvents.WEB_DEVELOPMENT.CTA.ESTIMATE_PROJECT
                }
                intoCallAnalytics={
                    gaTrackedEvents.WEB_DEVELOPMENT.CTA.INTRO_CALL
                }
            />
            <WhyWebDevSection />
            <CareOfQualitySection />
            <QuoteBannerSection
                quote="quotes.centralComplexChallenge"
                author="quotes.stephanieAuer"
                position="quotes.projectManagerDigitty"
            />
            <PartnershipSection />
            <BadgesSection />
            <PerksWithImageSection
                title="view-web-projects-solution-section.title"
                perks={PERKS}
            />
            <SpecializationsWallSection />
            <ServicesCaseStudiesSection
                caseStudiesData={useCaseStudiesData()}
                subtitleId="case-studies.desc"
            />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </>
    );
};

WebDevelopmentView.propTypes = Props;
