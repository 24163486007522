import React from 'react';
import { CASE_STUDIES_TAG } from 'views/case-studies/constants';
import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import JavascriptSvg from 'svgs/blog/javascript.svg';
import Card from 'svgs/icons/ic-card-pos.svg';
import Magnifier from 'svgs/icons/ic-competition.svg';
import User from 'svgs/icons/ic-profile-user.svg';
import TrendUp from 'svgs/icons/ic-trend-up.svg';
import GatsbySvg from 'svgs/technologies/ic-gatsby.svg';
import NestSvg from 'svgs/technologies/ic-nest.svg';
import NextSvg from 'svgs/technologies/ic-next.svg';
import PwaSvg from 'svgs/technologies/ic-pwa.svg';

export const TILES_DATA = [
    {
        icon: <User width={42} height={42} />,
        text: 'view-web-projects-tiles-section.customers.title',
        description: 'view-web-projects-tiles-section.customers.desc',
    },
    {
        icon: <Magnifier width={42} height={42} />,
        text: 'view-web-projects-tiles-section.competition.title',
        description: 'view-web-projects-tiles-section.competition.desc',
    },
    {
        icon: <Card width={42} height={42} />,
        text: 'view-web-projects-tiles-section.sales.title',
        description: 'view-web-projects-tiles-section.sales.desc',
    },
    {
        icon: <TrendUp width={42} height={42} />,
        text: 'view-web-projects-tiles-section.profits.title',
        description: 'view-web-projects-tiles-section.profits.desc',
    },
];

const path = 'view-web-projects-solution-section';
export const PERKS = [
    {
        title: `${path}.webDevelopmentTeam.title`,
        text: `${path}.webDevelopmentTeam.desc`,
        details: [
            `${path}.webDevelopmentTeam.details.0`,
            `${path}.webDevelopmentTeam.details.1`,
            `${path}.webDevelopmentTeam.details.2`,
            `${path}.webDevelopmentTeam.details.3`,
            `${path}.webDevelopmentTeam.details.4`,
            `${path}.webDevelopmentTeam.details.5`,
        ],
    },
    {
        title: `${path}.detailedCooperationProcess.title`,
        text: `${path}.detailedCooperationProcess.desc`,
        details: [
            `${path}.detailedCooperationProcess.details.0`,
            `${path}.detailedCooperationProcess.details.1`,
            `${path}.detailedCooperationProcess.details.2`,
            `${path}.detailedCooperationProcess.details.3`,
            `${path}.detailedCooperationProcess.details.4`,
            `${path}.detailedCooperationProcess.details.5`,
            `${path}.detailedCooperationProcess.details.6`,
        ],
    },
    {
        title: `${path}.webDevelopmentServices.title`,
        text: `${path}.webDevelopmentServices.desc`,
    },
];

export const useCaseStudiesData = () => {
    const images = useStaticQuery(graphql`
        {
            desaLocum: file(
                relativePath: { eq: "case-studies/desa-locum-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
            turo: file(
                relativePath: { eq: "case-studies/turo-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
            nftReality: file(
                relativePath: { eq: "case-studies/nft-reality-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            title: 'case-studies-page.desaLocum',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.desaLocum.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.REAL_ESTATE_AUCTION_SOFTWARE}`,
        },
        {
            title: 'case-studies-page.turo',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.turo.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.CONSTRUCTION_MANAGEMENT_SOFTWARE_TURO}`,
        },
        {
            title: 'case-studies-page.nftReality',
            field: 'case-studies-page.field.nftBlockchain',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.SMART_CONTRACT_DEVELOPMENT,
                CASE_STUDIES_TAG.NFT_DEVELOPMENT,
            ],
            image: images.nftReality.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.NFT_REALITY}`,
        },
    ];
};

export const SPECIALIZATIONS_DATA = [
    {
        icon: <NextSvg />,
        title: 'specializations.next.title',
        description: 'specializations.next.description',
        to: `${PATHS.BLOG}/nextjs-server-sider-rendering-guidline`,
        label: 'general.readMore',
    },
    {
        icon: <GatsbySvg />,
        title: 'specializations.gatsby.title',
        description: 'specializations.gatsby.description',
        to: `${PATHS.BLOG}/gatsbyjs-the-ultimate-guideline`,
        label: 'general.readMore',
    },
    {
        icon: <NestSvg />,
        title: 'specializations.nest.title',
        description: 'specializations.nest.description',
        to: `${PATHS.BLOG}/advantages-and-disadvantages-of-nestjs`,
        label: 'general.readMore',
    },
    {
        icon: <PwaSvg />,
        title: 'specializations.pwa.title',
        description: 'specializations.pwa.description',
        to: `${PATHS.BLOG}/progressive-web-app-development-guideline-for-ctos`,
        label: 'general.readMore',
    },
];

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    showAllArticles: true,
    tags: [BLOG_POST_TAGS.BACKEND, BLOG_POST_TAGS.FRONTEND],
    button: {
        to: `${PATHS.BLOG}/${PATHS.JAVASCRIPT}`,
        label: 'view-web-footer.button',
    },
    categoryBanner: {
        title: 'view-web-footer.title',
        description: 'view-web-footer.description',
        svg: JavascriptSvg,
    },
};
