import React from 'react';
import { useIntl } from 'react-intl';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BadgesSection as BadgesSectionContainer } from 'modules/badges-section';
import { StyledLink } from 'modules/page-footer/components/styled-link';
import ClutchTopProductLogo from 'svgs/logos/clutch-top-product.svg';
import TopAppDevelopersLogo from 'svgs/logos/top-app-developers.svg';

export const BadgesSection = () => {
    const { formatMessage } = useIntl();

    const images = useStaticQuery(graphql`
        {
            web: file(
                relativePath: {
                    eq: "top-it-companies-badges/web-developers.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
            nodejs: file(
                relativePath: { eq: "top-it-companies-badges/nodejs.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <BadgesSectionContainer>
            <StyledLink
                href={EXTERNAL_REDIRECTS.CLUTCH_TOP_COMPANY_BADGE_URL}
                ariaLabel={formatMessage({
                    id: 'footer.clutch.alts.productDesignCompany',
                })}
            >
                <ClutchTopProductLogo width={240} height={259} />
            </StyledLink>
            <StyledLink
                href={EXTERNAL_REDIRECTS.TOP_APP_DEVELOPERS}
                ariaLabel={formatMessage({
                    id: 'footer.topAppDevelopers.alt',
                })}
                rel="noopener dofollow"
                title={formatMessage({
                    id: 'footer.topAppDevelopers.title',
                })}
            >
                <TopAppDevelopersLogo width={291} height={204} />
            </StyledLink>
            <StyledLink
                href={EXTERNAL_REDIRECTS.TOP_IT_COMPANIES_WEB_DEVELOPERS}
                ariaLabel={formatMessage({
                    id: 'view-web-projects-badges-section.web.alt',
                })}
                title={formatMessage({
                    id: 'view-web-projects-badges-section.web.title',
                })}
            >
                <GatsbyImage
                    image={images.web.childImageSharp.gatsbyImageData}
                    alt={formatMessage({
                        id: 'view-web-projects-badges-section.web.alt',
                    })}
                />
            </StyledLink>
            <StyledLink
                href={EXTERNAL_REDIRECTS.TOP_IT_COMPANIES_NODE_JS}
                ariaLabel={formatMessage({
                    id: 'view-web-projects-badges-section.web.alt',
                })}
                title={formatMessage({
                    id: 'view-web-projects-badges-section.web.title',
                })}
            >
                <GatsbyImage
                    image={images.nodejs.childImageSharp.gatsbyImageData}
                    alt={formatMessage({
                        id: 'view-web-projects-badges-section.web.alt',
                    })}
                />
            </StyledLink>
        </BadgesSectionContainer>
    );
};
