import React from 'react';
import { TILES_DATA } from 'views/web-development/constants';
import { SectionTilesSection } from 'modules/section-tiles-section';

export const WhyWebDevSection = () => (
    <SectionTilesSection
        title="view-web-projects-tiles-section.whyWebDevelopment"
        paragraph="view-web-projects-tiles-section.webDevSolutions"
        data={TILES_DATA}
    />
);
