import React from 'react';
import { WebDevelopmentView } from 'views/web-development';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import messages from 'constants/translations/services/web-development.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';
import { formatFaqData } from 'utilities/contentful';

const WebProjectsPage = ({ location, data }) => {
    const faqData = formatFaqData(data?.allContentfulFaq.nodes[0]);

    return (
        <Layout
            location={location}
            messages={{
                ...messages,
                ...caseStudiesMessages,
            }}
        >
            <WebDevelopmentView {...{ faqData }} />
        </Layout>
    );
};

WebProjectsPage.propTypes = {
    location: object.isRequired,
};

export default WebProjectsPage;

export const Head = () => <SEO tags={TAGS.WEB_DEVELOPMENT} />;

export const pageQuery = graphql`
    query WebDevelopmentPageQuery {
        allContentfulFaq(filter: { slug: { eq: "services/web-development" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
